.pag {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
    margin-top: 75px;
}

.pag .subt {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-right: 15px;
    font-size: 12px;
    color: #929292;
    text-align: center;
    margin-top: 0;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
}
.title-table{
    border: 1px solid yellow;
}
.pag-with-navbar {
    margin-left: 15%;
    margin-top: 75px;
}

.data-precificador {
    margin-right: 20px;
    display: flex;
    margin-top: 75px;
    justify-content: space-evenly;
    width: 600px;
}

.data-precificador input {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: none;
    border-bottom: 1px solid black;
    background-color: #f2f2f2;
}

.cinfo {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
}

.mcc-list-h{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 500px;
    line-height: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
}

.mcc-list{
    border-left: 10px;
    align-content: center;
    column-gap: 10px;
    line-height: 12px;
    padding-left: 15px;
    padding-right: 5px;
    font-size: 12px;
    width: 250px;
}

.mcc-l{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 5px;
    margin-left: 10px;
    height: 250px;
    width: auto;
    border: 1px solid rgb(165, 165, 165);
}
.vs {
    width: 100%;
    max-width: 50px;
}

.ot {
    width: 100%;
    max-width: 45px;
}
.i-prec{
    font-family: 'Montserrat';
}


.ip-tx{
    font-size: 14px;
    background-color: #E2EFD9;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: none;
}
.ip-tx:disabled{
    background-color: #dcdcdc;
    border: none;
}
.input-data:disabled{
    background-color: #a8a8a8;
    cursor: not-allowed;
    color: black;
}

.antecipacao {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 2px;
    margin-top: 15px;
    width: 99.4%;
    background-color: #E2EFD9;
    text-align: center;
}

.table_title {
    width: 400px;
    margin-top: 0;
    line-height: 10px;
    text-align: center;
}

table tr.item td {
    font-size: 14px;
    background-color: #E2EFD9;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

table tr.heading td {
    background: #eee;
    padding: 3px;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.btn-prec {
    display: flex;
    justify-content: space-evenly;
    max-width: 900px;
    width: auto;
    margin-right: 20px;
}

.btn-prec-livre{
    display: flex;
    justify-content: space-evenly;
    max-width: 900px;
    width: auto;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-prec-livre button {
    margin-top: 10px;
    margin-right: 10px;
    width: 300px;
    border: none;
    padding: 2px;
    height: 25px;
    color: white;
    font-size: 11px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}
.btn-prec-livre a{
    padding-top: 10px;
    font-size: 11px;
    width: 250px;
    vertical-align: middle;
    color:rgb(117, 116, 116);
    text-decoration: none;
}
.btn-prec-livre a:hover{
    color:rgb(1, 68, 78);
}
.btn-prec-livre button:hover{
    color: rgb(193, 192, 192);
    background-color: #b27826;
}
.btn-prec-livre button:disabled{
    background-color: #55370d;
    color: #929292;
    cursor: not-allowed;
}

.btn-prec-semi {
    display: flex;
    justify-content: space-evenly;
    max-width: 900px;
    width: auto;
    margin-right: 20px;
}

.btn-prec-semi a{
    padding-top: 10px;
    font-size: 11px;
    width: 250px;
    vertical-align: middle;
    color:rgb(117, 116, 116);
    text-decoration: none;
}

.btn-prec-semi a:hover{
    color:rgb(1, 68, 78);
}

.btn-prec-semi button {
    margin-top: 10px;
    margin-right: 10px;
    width: 300px;
    border: none;
    padding: 2px;
    height: 25px;
    color: white;
    font-size: 11px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}

.btn-prec-semi button:hover{
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.btn-prec-semi button:disabled{
    background-color: #55370d;
    color: #929292;
    cursor: not-allowed;
}

.btn-prec button {
    margin-top: 10px;
    margin-right: 10px;
    width: 300px;
    border: none;
    padding: 2px;
    height: 25px;
    color: white;
    font-size: 12px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}

.btn-prec button:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.btn-prec button:disabled {
    background-color: #55370d;
    color: #929292;
    cursor: not-allowed;
}

.foot {
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 10px;
    flex-direction: row;
    margin-left: 3px;
    font-size: 12px;
    justify-content: space-evenly;
    color: rgb(0, 0, 0);
    width: 99.4%;
    background-color: inherit;
}

.info {
    font-family: 'Montserrat';
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
}

table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.lg-v {
    max-width: 150px;
    width: auto;
}

.information {
    font-size: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.information b {
    font-weight: 900;
}

/* Responsividade telas precificador */
@media only screen and (max-width: 768px) {
    .tb-p{
        margin-left: 10px;
    }
    .page-with-navbar {
        width: 100%;
        margin-left: 0;
    }

    .i-prec {
        width: 80%;
        margin-top: 15px;
    }

    .p-form {
        flex-direction: column;
        width: 350px;
        align-items: center;
    }

    .data-precificador {
        flex-direction: column;
        width: 250px;
    }

    .mcc-l {
        width: 400px;
        height: 1000px;
        display: flex;
        flex-direction: column;
    }

    .mcc-list-h {
        max-width:360px;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }

    .mcc-list {
        height: 400px;
    }

    .data-precificador input {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .btn-prec {
        flex-direction: column;
        margin-left: 25px;
        bottom: 50px;
    }

    .cinfo {
        margin-left: 50px;
        width: 680px;
    }

    .table_title {
        margin-left: 20%;
        max-width: 400px;
        font-size: 12px;
    }

    .vs {
        width: 100%;
        max-width: 30px;
    }

    .ot {
        width: 100%;
        max-width: 25px;
    }

    .information {
        margin-left: 20%;
    }

    .lg-v {
        max-width: 150px;
        width: auto;
        margin-left: 10%;
    }

    table {
        max-width: 400px;
        margin-left: 50px;
    }

    table tr.item td {
        font-size: 12px;
    }

    table tr.heading td {
        font-size: 12px;
    }

    .antecipacao {
        font-size: 13px;
        max-width:450px;
    }
}