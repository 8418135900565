.analyzesContainer {
    top:20%;
    margin-left: 12%;
    background-color: #f1f1f1;
    width: 70%;
    text-align: justify;
    border-radius: 20px;
    margin-bottom: 50px;
    height: 100%;
}
.analyzesButton{
    margin-bottom: 20px;
}
.hidden {
    display: none;
}
.backButtonContainer{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.pdfButton{
    font-family: 'Montserrat';
    margin-left: 90%;
    font-size: 12px;
    width: auto;
    background-color: inherit;
    border:none;
}
.pdfDate{
    margin-left: 20px;  
}
.pdfButton:hover{
    background-color:rgb(231, 231, 231);
}
.pdfTitle{
    text-align: center;
}
.pdfDescription{
    padding-left: 20px;
    font-family: 'Montserrat';
}
.videoFrame{
    margin-bottom: 10px;
}