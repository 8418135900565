:root{
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Urbane';
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

/* Importação das fontes da valori */
@font-face {
  font-family: 'Urbane';
  src: url(../public/fonts/Urbane-DemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'UrbaneRegular';
  src: url(../public/fonts/Urbane-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat';
  src: url(../public/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

/* Animação de transição ao carregar a página */
@keyframes transitionIn {
  from{
      opacity: 0;
      transform: translateY(10px);
  }
  to{
      opacity: 1;
      transform: translateY(0);
  }
}

