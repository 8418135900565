
.options{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    height: auto;
}
.option-btn{
    font-family: 'Urbane';
    margin-top: 10px;
    width: 200px;
    border: none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
}
.option-btn:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.page {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
    margin-top: 100px;
}

.page-with-navbar {
    margin-left: 15%;
    margin-top: 100px;
}

@media only screen and (max-width: 768px) {
    .page-with-navbar {
        width: 100%;
        margin-left: 0px;
    }

    h2 {
        font-size: 15px;
    }

    .req-input {
        display: flex;
    }

    .req-input input {
        width: 100px;
    }
}