.header {
    display: flex;
    padding: 14px 14px;
    align-items: center;
    justify-content: space-between;
    color: #102C28;
    font-size: 16pt;
    font-weight: bold;
    background-color: white;
    width: 100%;
    height: 100px;
    border: none;
}

.img {
    background: url(../../../public/images/logo_nav.png);
    margin-left: 3px;
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border: none;
}

.header button {
    display: flex;
    flex-direction: column;
    padding: 2px;
    margin-left: 16px;
    margin-right: 16px;
    color: #F0EEEE;
    text-decoration: none;
    font-family: 'UrbaneRegular';
    font-size: 10px;
}


@media screen and (max-width: 730px) {
    .img {
        display: hide;
    }
}

@media screen and (max-width: 600px) {
    .header a {
        font-size: 18px;
    }
}

@media screen and (max-width: 565px) {
    .header a {
        font-size: 16px;
    }
}

.headerNav {
    position: fixed;
    background-color: #0f2c28;
    top: 0;
    left: 0;
    height: 100%;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
}

.headerNav button {
    height: 20px;
    width: 20px;
    background: none;
    border: none;
}


.logo_navbar {
    top: 0;
    padding: 5px 10px;
    display: flex;
    background-size: cover;
    background-image: url('../../../public/images/logo_nav.png');
    width: 66px;
    height: 64px;
}

.logoutButton {
    display: flex;
    text-decoration: none;
    color: white;
    margin-bottom: 30px;
    justify-content: start;
    margin-top: 10px;
}

.logoutButton:hover {
    background-color: #264a4e;
}

.nav {
    font-family: 'Montserrat';
    position: fixed;
    top: 85px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #0f2c28;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: transform 1s;
}

.navbar {
    transform: translateX(-100%);
}

.nav-btn {
    position: absolute;
    transform: translateX(38px);
    top: 35px;
    right: 0;
    width: 40px;
    height: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0f2c28;
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    color: #ffffff;
}

.nav-link {
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    width: 100%;
}
.nav-link-adduser{
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    width: 100%;
}
.nav-link-adduser span {
    margin-left: 10px;
    text-decoration: none;
    color: white;
}
.nav-link-adduser:hover {
    background-color: #19413b;
    color: #4e4e4e;
}
.nav-link:hover {
    background-color: #19413b;
    color: #4e4e4e;
}

.links {
    text-decoration: none;
    color: white;
}

.nav-link span {
    margin-left: 10px;
    text-decoration: none;
    color: white;
}

.nav-top {
    margin-top: 10rem;
}

.span {
    text-decoration: none;
}

.logo {
    display: block;
    width: 100px;
    margin: auto;
    background: transparent;
}

.logo img {
    display: block;
    width: 100%;
    height: 100%;
}



.mobile-nav {
    width: 100%;
    height: 50px;
    display: flex;
    position: fixed;
}

.mobile-nav-btn {
    position: fixed;
    color: #ffffff;
    display: flex;
    justify-content: start;
    background: transparent;
    outline: none;
    border: none;
    margin: 0 10px;
}
.paragraph{
    display: none;
}
.nav-link-mobile{
    display: none;
    margin-top: 10px;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    width: 100%;
}
.nav-link-mobile span {
    margin-left: 10px;
    text-decoration: none;
    color: white;
}
.nav-link-mobile:hover {
    background-color: #19413b;
    color: #4e4e4e;
}

@media only screen and (max-width: 768px) {
    .nav {
        width: 25%;
        padding: 10px;
        margin-top: 45px;
    }
    .mobile-nav-btn {
        position: fixed;
        color: #ffffff;
        background: inherit;
        outline: none;
        border: none;
        margin: 40px 90px;
        margin-left: 25px;
        font-size: 10px;
        align-items: center;
    }
    .mobile-nav{
        top:0;
        background-color: #0f2c28;
    }
    .nav-link-mobile{
        display: flex;
    }
    .logo img{
        width: 85%;
        height: 85%;
    }
    .nav-link-adduser{
        display: none;
    }
    
}

@media only screen and (max-width: 450px) {
    .paragraph{
        display: block;
        margin-left: 15px;
    }
    .mobile-nav {
        display: flex;
        align-items: center;
        justify-items: start;
        top:0;
        background-color: #0f2c28;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
    }

    .nav {
        width: 20%;
        top:0;
        min-height: calc(100vh - 38px);
    }

    .nav-btn {
        display: none;
    }
    .nav-link span {
        margin-left: 1px;
        text-decoration: none;
        color: white;
    }
    .span{
        font-size: 10px;
    }
    .logo img{
        width: 85%;
        height: 85%;
    }
}
