.birthdayModelContainer {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    display: flex;
    margin-left: 20px;
    justify-content: flex-start;
    align-content: space-between;
}

.legenda {
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.dados_calendario {
    width: 500px;
}

.dados_calendario p {
    font-family: 'Montserrat';
}

#azul {
    color: #025971;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 10px;
}

#dourado {
    color: #f4a333;
    font-weight: bold;
}

.titulo_calendario {
    font-family: 'Montserrat';
    text-align: center;
    font-size: 26px;
}

.subtitulo_calendario {
    font-family: 'Montserrat';
    margin-left: 20px;
}


.react-calendar {
    margin-left: 150px;
    width: 700px;
}

.react-calendar__month-view__days {
    height: 350px;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
    background-color: rgb(238, 238, 238);
    border: 1px solid lightgray;
    font-size: 16px;
}

.react-calendar__month-view__days__day:hover,
.react-calendar__month-view__days__day--weekend:hover {
    background-color: rgb(220, 219, 219);
}

.highlight {
    background-color: #f4a333;
    color: white;
}

.today {
    background-color: #025971;
    color: white;
}

.today:hover {
    background-color: #01242d;
}

.highlight:hover {
    background-color: #95621b;
}

.react-calendar__month-view__weekdays__weekday {
    font-family: 'Montserrat';
    font-weight: 500;
    text-align: center;
    border: none;
}

abbr[title='domingo'] {
    color: red;
}

abbr[title] {
    text-decoration: none;
    font-variant-caps: all-petite-caps;
}

.react-calendar__month-view__days__day--neighboringMonth {
    background-color: rgb(249, 247, 247);
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px;
    height: 30px;
}

.react-calendar__navigation button {
    font-size: 14px;
    background-color: white;
    border: none;
    border-bottom: 1px solid black;
    font-weight: bold;
}

.react-calendar__navigation button:hover {
    background-color: rgb(220, 219, 219);
}

.react-calendar__navigation__label {
    max-width: 250px;
}

.react-calendar__navigation button {
    text-transform: capitalize;
}

.page {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: margin-left 1s;
    top: 100px;
    margin-top: 95px;
}

.page-with-navbar {
    margin-left: 15%;
}

#calendarContainer {
    display: flex;
    flex-direction: row;
    padding-left: 0%;
    margin-left: 0;
}

@media only screen and (max-width: 768px) {
    .page-with-navbar {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .react-calendar {
        margin-left: 10px;
        width: 500px;
    }

    .react-calendar__month-view__days {
        height: 250px;
    }

    .react-calendar__month-view__days__day,
    .react-calendar__month-view__days__day--weekend {
        font-size: 14px;
    }

    .react-calendar__month-view__weekdays__weekday,
    .react-calendar__navigation button {
        font-size: 12px;
    }

    #calendarContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 50vw;
        height: 50%;
        margin-right: 10px;
        margin-left: 0;
        padding-left: 0;
    }

    .titulo_calendario {
        font-size: 20px;
    }

    .dados_calendario {
        display: flex;
        flex-direction: column;
        width: 85%;
        height: 250px;
        padding-left: 0%;
    }

    h3 {
        font-size: 14px;
    }
    .titulo_calendario{
        font-size: 15px;
    }
    .birthdayModelContent, .birthdayModelContainer{
        font-size: 12px;
    }
    .legenda {
        font-size: 10px;
    }
}