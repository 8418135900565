:root {
    background-color: white;
}

.backButton {
    color: white;
    font-family: 'Urbane';
    width: 100px;
    background-color: #f44a33;
    border: none;
    border-radius: 50px;
    font-size: 20px;
}

.backButton:hover {
    color: rgb(193, 192, 192);
    background-color: #b53423;
}
.videoFrame{
    margin-top: 25px;
    margin-bottom: 25px;
}

.department-list {
    margin-top: 50px;
    margin-left: 15px;
    display: flex;
    justify-content: space-evenly;
}

.departmentButton {
    display: block;
    margin-left: 15px;
    font-family: 'Urbane';
    background-color: inherit;
    color: rgb(0, 0, 0);
    font-size: 20px;
    border: none;
    border-bottom: 1px solid gray;
}

.departmentButton:hover {
    color: #b27826;
    border-bottom: 1px solid #b27826;
}

.page {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
}

.page-with-navbar {
    margin-left: 15%;
}
@media screen and (max-width: 900px) {
    .departmentButton {
        font-size: 18px;
    }
    .department-list{
        flex-direction: column;
        margin-left: 0px;
    }
}

@media screen and (max-width: 800px) {
    .departmentButton {
        font-size: 16px;
    }
    .department-list{
        flex-direction: column;
        margin-left: 0px;
    }
}

@media screen and (max-width: 700px) {
    .departmentButton {
        font-size: 14px;
    }
    .department-list{
        flex-direction: column;
        margin-left: 0px;
    }
}

@media screen and (max-width: 650px) {
    .departmentButton {
        font-size: 18px;
    }
    .department-list{
        flex-direction: column;
        margin-left: 0px;
    }
}
@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 0;
	}
    .videoFrame{
        width: 300px;
        height: 300px;
        left:0;
    }
    .departmentList{
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 0px;
    }
}