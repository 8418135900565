.dashboard {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    overflow: hidden;
    font-family: 'Urbane';
    margin-left: 80px;
}

.homeButton {
    cursor: pointer;
    background-color: #f4a333;
    color: white;
    max-width: 100px;
    height: 20px;
    font-weight: bold;
    border:none;
    border-radius: 50px;
    margin-left: 20%;
    margin-bottom: 15px;
}

.texto {
    animation: transitionIn 2s;
    margin-top: 64px;
    margin-bottom: 120px;
    text-align: center;
    font-family: 'UrbaneRegular';
}

.homeContainer {
    display: flex;
    flex-direction: column;
    color: black;
}

.newsContaienr {
    margin-top: 32px;
}

.iframeContainer {
    width: 50%;
}

.iframeContainer iframe {
    text-align: center;

    display: block;
    /* iframes are inline by default */
    height: 400px;
    /* Set height to 100% of the viewport height */
    width: 97%;
    /* Set width to 100% of the viewport width */
    border: none;
    /* Remove default border */
    background: lightyellow;
    /* Just for styling */
}

#homeTitle {
    font-size: 30px;
}

#newsTitle {
    font-size: 36px;
    font-family: 'UrbaneRegular';
    text-align: center;
    color: rgb(0, 0, 0);
}

#iframeContainerTitle {
    font-size: 36px;
    font-family: 'UrbaneRegular';
    text-align: center;
    color: rgb(0, 0, 0);
}

.visualContainerHost {
    width: 100%;
}


.page {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: margin-left 1s;
    top:100px;
    margin-top: 100px;
}

.page-with-navbar {
	margin-left: 15%;
    margin-top: 100px;
}

.imageSlider{
    margin-top: 50px;
    width: 1150px;
    height: 500px;
}

@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 5px;
	}
    .dashboard{
        margin-left: 10px;
    }
    .imageSlider{
        display: none;
        width: 85vw;
        height: 200px;
    }
    .newsContaienr{
        font-size: 10px;
    }
}