.upperHeader {
    display: flex;
    flex-direction: row;
    top: 0%;
    width: 100%;
    height: 85px;
    position: fixed;
    background-color: #f1f1f1;
    text-decoration: none;
    align-items: center;
    z-index: 20;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.6);
}

.upperHeader li {
    display: flex;
    flex-direction: row;
    right: 0%;
    width: 100vw;
    align-items: center;
    justify-content: space-around;
    list-style-type: none;
}
.upperHeader h6 {
    color: rgb(118, 116, 116);
    cursor: pointer;
}
.upperHeader h6:hover{
    color: black;
}
.upperHeader a {
    cursor: pointer;
    display: flex;
    right: 0%;
    width: 95vh;
    align-items: center;
    justify-content:center;
    list-style-type: none;
}

.upperHeader a:hover{
    color:rgb(87, 87, 87);
}

.header_user {
    background-image: url('../../images/user (1).png');
    background-size: cover;
    height: 24px;
    width: 24px;
}
.header_user_edu {
    background-image: url('../../images/Constellation\ Logo.png');
    background-size: cover;
    height: 30px;
    width: 35px;
}
.header_user_costa{
    background-image: url('../../images/costa.jpeg');
    background-size: cover;
    height: 30px;
    width: 35px;
}
.header_user_bernardo{
    background-image: url('../../images/bernardo.png');
    background-size: cover;
    height: 30px;
    width: 35px;
}
.header_user:hover{
    cursor: pointer;
}
#profileHeader{
    display: flex;
    flex-direction: row;
    width: 50vw;
    align-content:center;
    align-self: center;
}

.header_faq {
    background-image: url('../../images/information.png');
    background-size: cover;
    margin-right: 10px;
    height: 24px;
    width: 24px;
}
.header_faq:hover{
    cursor: pointer;
}
.headerText {
    display: flex;
    color: black;
    margin-left: 0;
    font-size: 15px;
    align-self: center;
    align-items: center;
    flex-wrap: nowrap;
}

.profile_faq{
    width: 230px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.b-e{
    cursor: pointer;
    color:rgb(16, 179, 151);
}

@media (max-width: 900px) {
    #profileHeader {
        display: none;
    }
    .upperHeader{
        display: none;
    }
}

