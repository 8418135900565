.FAQ_container{
    margin-left: 60px;
    margin-top: 50px;
    background-color: white;
    width: 75vw;
    text-align: justify;
    border: none;
    margin-bottom: 50px;
}

.FAQ_container a{
    text-decoration: none;
    color:#fbab3a;
    margin-bottom: 20px;
}

.FAQ_container a:hover{
    color:#b27826;
}
.FAQ_container p{
    font-family:'Montserrat';
}

.SearchBar{
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-around;
    width: 350px;
}

.SearchBar input{
    border: none;
    width: 100%;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.btnRegistrarFAQ{
    background-color: #fbab3a;
    border-radius: 50px;
    border: none;
    color:white;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 10px;
}

.btnRegistrarFAQ:hover{
    background-color:#b27826;
    color:rgb(234, 234, 234);
}  
.questionModelContent {
    white-space: pre-line;
    white-space: pre-wrap;
    text-align: justify;
}
.page {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: margin-left 1s;
    top:100px;
    margin-top: 95px;
}

.page-with-navbar-FAQ {
	margin-left: 0%;
}

@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 5px;
	}
    .perguntas{
        margin-left: 0;
        padding-left: 0%;
    }
    .FAQ_container{
        margin-left: 0;
        padding-left: 0%;
        text-align: start;
    }
    .dashboard{
        margin-left: 10px;
    }
    .btnRegistrarFAQ{
        display: none;
    }
    .SearchBar{
        width: 280px;
    }
}