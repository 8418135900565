#orcamentoContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: scroll;
    margin-right: 12px;
    height: 80vh;

}

#orcamentoContainer th {
    padding-right: 32px;
}


#orcamentoContainer tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table-content:hover {
    background-color: rgb(201, 201, 201);
}

.table-content {
    font-family: 'Montserrat';
}

#orcamentoContainer table tbody tr {
    font-family: 'Montserrat';
    height: 50px;
}

.table-header {
    background: #023a37;
    position: sticky;
    height: 10px;
    padding: 2px;
    top: 0%;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 18px;
    color: white;
    line-height: 1.2;
    font-weight: unset;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.table-highlight {
    font-weight:bolder;
    background-color: #fff9e6;
    cursor: pointer;
}

.table-sub {
    font-weight: bolder;
    cursor: pointer;
}
.table-highlight:hover{
    background-color: #a09c8d;
}
.table-highlight:hover{
    background-color: #a09c8d;
}
.table-highlight:hover{
    background-color: #a09c8d;
}

.receita-subcateg-hidden,
.despesa-subcateg-hidden,
.receita-item-hidden,
.despesa-item-hidden {
    display: none;
}

.receita-subcateg,
.receita-item,
.despesa-subcateg,
.despesa-item {}

.req-input {
    display: flex;
}

.req-input input {
    font-family: 'Montserrat';
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid black;
    background-color: #f2f2f2;
}

.req-btn {
    font-family: 'Urbane';
    margin-top: 10px;
    width: 200px;
    border: none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
}

.req-btn:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.download {
    font-family: 'Montserrat';
    border: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: inherit;
    border-radius: 10px;
}

.download:hover {
    background-color: #f2f2f2;
}
.res-fin{
    width: 760px;
}
.contas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
}
.pagar,.corrente,.receber{
    margin-right: 20px;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}
.corrente,.receber{
    background-color: #dfdfdf;
}
.pagar{
    background-color: #f2f2f2;
}
.page {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
    margin-top: 100px;
}

.page-with-navbar {
    margin-left: 15%;
    margin-top: 100px;
}

@media only screen and (max-width: 768px) {
    .page-with-navbar {
        width: 100%;
        margin-left: 0px;
    }

    h2 {
        font-size: 15px;
    }

    .req-input {
        display: flex;
    }

    .req-input input {
        width: 100px;
    }
    #orcamentoContainer{
        width: 90vw;
        height: 80%;
    }
    .table-header{
        font-size: 14px;
        z-index: -1;
    }
    .table-content{
        font-size: 12px;
    }
}