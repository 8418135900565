:root {
    background-color: white;
    color: black;
}

.container_news {
    width: 280px;
    position: relative;
    color: black;
}

.container_news input {
    color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: none;
    outline: none;
    background: #f1f1f1;
    border-radius: 50px;
}

.container_news textarea {
    color: black;
    border: none;
    outline: none;
    background: #f1f1f1;
    border-radius: 10px;
    resize: none;
}

.newsContaienr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.news {
    margin-left: 60px;
    background-color: white;
    width: 80vw;
    text-align: justify;
    margin-bottom: 50px;
}

#newsTitle {
    width: 100vw;
    text-align: center;
}

.news_btn {
    width: 100%;
    font-family: 'Urbane';
    border: none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
    margin-bottom: 10px;
}

.news_btn:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.news_btn_back {
    font-family: 'Urbane';
    width: 100%;
    border: none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f44a33;
    cursor: pointer;
}

.news_btn_back:hover {
    color: rgb(193, 192, 192);
    background-color: #b53423;
}

.addButton {
    font-family: 'Urbane';
    margin-left: 25px;
    margin-bottom: 20px;
    margin-top: 50px;
    width: 150px;
    border: none;
    border-radius: 10px;
    background-color: #f4a333;
    font-size: 15px;
    color: white;
}

.addButton:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.newsModelContainer {
    background-image: url('../../../public/images/news_background.png');
    background-size: cover;
    color: white;
    min-width: 100px;
    width: 565px;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 20px;

    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;

    border: none;
    border-radius: 12px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.6);
}

.newsModelContainer p {
    font-family: 'Montserrat';
}

#newsModelTitle {
    color: #fad5a2;
    font-size: 24px;
    text-align: center;
}

#newsModelContent {
    font-size: 20px;
    text-align: justify;
    margin-left: 8px;
    margin-right: 8px;
    white-space: pre-line;
    white-space: pre-wrap;
}

#newsModelDate {
    font-weight: 400;
    font-size: 11px;
    text-align: center;
}

#del_btn {
    font-family: 'Urbane';
    width: 70px;
    margin-bottom: 5px;
    border: none;
    padding: 0;
    height: 30px;
    color: #f44a33;
    font-size: 15px;
    cursor: pointer;
    background-color: inherit;
}

#del_btn:hover {
    color: #b53423;
}

.page {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: margin-left 1s;
    top: 250px;
}

.page-with-navbar {
    margin-left: 15%;
    top: 250px;
}

@media only screen and (max-width: 768px) {
    .page-with-navbar {
        width: 100%;
        margin-left: 0;
    }

    .dashboard {
        margin-right: 20px;
    }

    .newsContaienr {
        width: 75vw;
    }

    #newsModelContainer {
        font-size: 15px;
    }

    #newsModelContent {
        font-size: 15px;
    }
    .addButton{
        display: none;
    }
}