.profile_page{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 280px;
    position: absolute;
    top: 20%;
    color: black;
}

.profile_page button{
    font-family: 'Urbane';
    margin-top: 15px;
    text-decoration: none;
    text-align: center;
    width: 200px;
    padding: 0;
    height: 30px;
    border: none;;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
}
.show_pwd_update{
    background-color: inherit;
    height: 20px;
    width: 20px;
    border: none;
    background-size: cover;
}

.profile_page button:hover{
    color:rgb(193, 192, 192);
    background-color: #b27826;
}

.profile_page p{
    color:rgb(52, 51, 51);
    font-family:'Montserrat';
    font-size: 18px;
}

.update_profile_page {
    width: 280px;
    position: relative;
    top: 50%;
    color: black;
}

.update_profile_page input{
    font-family: 'Urbane';
    color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: none;
    outline: none;
    background: #f1f1f1;
    border-radius: 50px;
}
.update_profile_page input[type='date']{
    color:gray;
}
.update_profile_page input[type='text']{
    font-family: 'Montserrat';
    font-weight: bold;
}
.update_btn{
    font-family: 'Urbane';
    margin-bottom:10px;
    width: 100%;
    border:none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
}
.update_btn:hover{
    color:rgb(193, 192, 192);
    background-color: #b27826;
}
.update_btn_back{
    font-family: 'Urbane';
    width: 100%;
    border:none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f44a33;
    cursor: pointer;
}

.update_btn_back:hover{
    color:rgb(193, 192, 192);
    background-color: #b53423;
}
.page {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: margin-left 1s;
    top:100px;
    margin-top: 95px;
}

.page-with-navbar {
	margin-left: 15%;
}

@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 5px;
	}
    .profile_page{
        position: relative;
        display: flex;
        margin-left: 50px;
        padding-left: 20px;
    }
    .update_profile_page{
        padding-left: 20px;
        position: relative;
    }
}