:root {
    background-color: white;
}

.departmentList {
    margin-top: 50px;
    margin-left: 15px;
    display: flex;
    justify-content: space-evenly;
}

.invisibleDepartmentButton {
    display: none;
    font-family: 'Urbane';
    background-color: inherit;
    color: rgb(0, 0, 0);
    font-size: 20px;
    border: none;
}

.backButtonContainer {
    text-align: center;
    margin-top: 50px;
}

.optionsButtonContainer {
    display: flex;
    justify-content: space-evenly;
}

.backButton {
    color: white;
    font-family: 'Urbane';
    width: 100px;
    background-color: #f44a33;
    border: none;
    border-radius: 50px;
    font-size: 20px;
}

.backButton:hover {
    color: rgb(193, 192, 192);
    background-color: #b53423;
}

.departmentButton {
    display: block;
    margin-left: 15px;
    font-family: 'Urbane';
    background-color: inherit;
    color: rgb(0, 0, 0);
    font-size: 20px;
    border: none;
    border-bottom: 1px solid gray;
}

.departmentButton:hover {
    color: #b27826;
    border-bottom: 1px solid #b27826;
}

.analyzesButton {
    font-family: 'Urbane';
    margin-left: 5px;
    margin-right: 5px;
    width: 100px;
    border: none;
    border-radius: 10px;
    background-color: #f4a333;
    font-size: 16px;
    color: white;
}

.analyzesButton:hover {
    color: rgb(193, 192, 192);
    background-color: #b27826;
}

.backButton_geral {
    color: white;
    font-family: 'Urbane';
    width: 100px;
    background-color: #f44a33;
    border: none;
    left: 250px;
    border-radius: 50px;
    font-size: 20px;
}
.tpv-filter{
    font-family: 'Urbane';
    width: auto;
    color: #4a4646;
    border: none;
    background-color: rgb(224, 221, 221);
    cursor: pointer;
    margin: 1em 1em 0 1em;
    margin-top: 2em;
}

.backButton_geral:hover {
    color: rgb(193, 192, 192);
    background-color: #b53423;
}


@media screen and (max-width: 900px) {
    .departmentButton {
        font-size: 18px;
    }
}

@media screen and (max-width: 800px) {
    .departmentButton {
        font-size: 16px;
    }
}

@media screen and (max-width: 700px) {
    .departmentButton {
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    .departmentButton {
        font-size: 18px;
    }

    .departmentList {
        display: block;
    }
}

.visibleIframeContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 48px;
}

.displayAreaContainer newCanvasScrollbar droppableElement ui-droppable {
    display: flex;
    justify-content: center;
}

.titulo_dash {
    text-align: center;
}

#rocket {
    width: 535px;
    margin-left: 32vw;
}

.chart {
    text-align: center;
    padding-right: 10px;
    max-width: 1000px;
    width: 900px;
    max-height: 800px;
    margin-top: 30px;
}

.page {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
    margin-top: 50%;
}

.page-with-navbar {
    margin-left: 15%;
    margin-top: 50%;
}

@media only screen and (max-width: 768px) {
    .page-with-navbar {
        width: 100%;
        margin-left: 0;
    }

    .dashboard {
        margin-right: 20px;
    }

    .chart {
        width: 100vw;
        height: 800px;
        margin-left: 0;
        font-size: 8px;
    }

    .departmentList {
        margin-top: 0px;
    }

    .departmentButton {
        margin-top: 5px;
    }

    .visibleIframeContainer {
        width: 250px;
        margin-left: 35px;
    }

    h1 {
        font-size: 25px;
    }

}