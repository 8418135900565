#addMain {
    color: rgb(0, 0, 0);
    margin-top:100px;
}

#addMain h1{
    text-align: center;
}
.show_pwd_add{
    background-color: inherit;
    height: 24px;
    width: 30px;
    border: none;
    background-size: cover; 
}
#addFormContainer {
    width: 280px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}
#addBox input {
    font-family: 'Montserrat';
    font-weight: bold;
    color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: none;
    outline: none;
    background: #f1f1f1;
    border-radius: 50px;
}

::placeholder {
    color: #6d6e6e;
}

.btn_add {
    font-family: 'Urbane';
    width: 100%;
    border:none;
    padding: 0;
    height: 30px;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    background-color: #f4a333;
    cursor: pointer;
}
.btn_add:hover{
    color:rgb(193, 192, 192);
    background-color: #b27826;
}
#select-box {
    position: relative;
    display: block;
    height: 40px;
    border: none;
    width: 100%;
    margin-top: 5px;
    font-size: 14px;
    border-radius: 50px;
    font-family: 'Montserrat';
    font-weight: bold;
    background-color: #f1f1f1;
    color: #6d6e6e;
}
.page {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: margin-left 1s;
    top:100px;
    margin-top: 100px;
}

.page-with-navbar {
	margin-left: 15%;
}

@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 5px;
	}
    .dashboard{
        margin-left: 10px;
    }
    h1{
        font-size: 20px;
    }
}