.page {
	flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: margin-left 1s;
    margin-top: 100px;
}

.page-with-navbar {
	margin-left: 15%;
    margin-top: 50px;
}
.h-form {
    margin-top: 100px;
}


@media only screen and (max-width: 768px) {
	.page-with-navbar {
		width: 100%;
		margin-left: 5px;
	}
    .dashboard{
        margin-left: 10px;
    }
    .imageSlider{
        display: none;
        width: 85vw;
        height: 200px;
    }
    .newsContaienr{
        font-size: 10px;
    }
}