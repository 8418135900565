.loading-icon{
    animation: rotation 0.8s infinite linear;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  